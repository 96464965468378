<!-- #Developer by Halil Kılıçarslan -->

<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    v-model="selected"
    :items="items"
    item-value="id"
    item-text="name"
    :loading="loading"
    :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: false }"
    color="tertiary"
  >
    <template v-if="$attrs.multiple" v-slot:selection="{ item, index }">
      {{ item.name }}
      <span v-if="index === 1" class="grey--text caption">(+{{ value.length - 1 }} kişi)</span>
    </template>
    <template v-else v-slot:selection="{ item, index }">
      {{ item.name }}
    </template>

    <template slot="item" slot-scope="data">
      <template>
        <v-list-item-avatar size="30" color="primary">
          <span class="white--text font-weight-bold">{{ data.item.name.substring(0, 2).toUpperCase() }}</span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-select>
</template>

<script>
  import * as GQL from "./gql";

  export default {
    inheritAttrs: false,
    name: "RolePicker",
    props: {
      value: [String, Object, Array]
    },
    data: vm => ({
      items: [],
      selected: null
    }),
    watch: {
      value: {
        immediate: true,
        handler(v) {
          this.selected = v;
        }
      }
    },
    methods: {
      fullName: item => item.firstName + " " + item.lastName,
      async fetchItems() {
        this.loading = true;
        await this.$apollo
          .query({
            query: GQL.ROLES,
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data: { roles }, errors }) => {
            if (!errors) {
              this.items = this.$helpers.sortBy(roles, "name");
              this.loading = false;
            }
          })
          .catch(e => {
            console.log("e", e);
            this.loading = false;
          });
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>

<style scoped></style>
